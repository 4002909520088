import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

import Image from 'molecules/Image';

import './BG.scss';

/**
 * With Background Image.
 */
const propTypes = {
  image:  PropTypes.object.isRequired, // See https://github.com/bluebill1049/react-simple-img#-simpleimg
  overlay: PropTypes.oneOf( [
    PropTypes.bool, //controlled via css.
    PropTypes.string, // controlled manually.
  ] ),
  Tag: PropTypes.string
}
const defaultProps = {
  Tag: 'div',
}
const WithBGImage = ( { children, className, image, overlay, Tag, ...p } ) => {

  let overlayStyles = {};
  if( typeof overlay === 'string' ) {
    overlayStyles.background = overlay;
  }
  else {
    overlayStyles = { ...overlay };
  }
  return(

    <Tag
    className={ classnames( 'BG', className ) }
    { ...p }
    >
      <div
      className="BG__img"
      >
        <Image { ...image } />
      </div>

      { !! overlay &&

        <div
        className="BG__overlay"
        style={ overlayStyles }
        />

      }

      <div className="BG__over">
        { children }
      </div>

    </Tag>

  );

};
WithBGImage.propTypes = propTypes;
WithBGImage.defaultProps = defaultProps;

export default WithBGImage;
