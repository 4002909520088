import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { useSetState } from 'react-use';

import { SimpleImg } from 'react-simple-img';

import './Image.scss';

/**
 * Image.
 *
 * @see https://github.com/bluebill1049/react-simple-img#-simpleimg
 */
const propTypes = {
  src: PropTypes.string,
  data: PropTypes.object,
  // See additional simpleimg props.
}
const Image = React.memo( ( { customClassName, src, data, ...p } ) => {

  const imageSrc = src ? src : !! data ? data.url : null;
  const placeholder = !! data ? !! data.metadata ?  data.metadata.lqip : data.lqip : null;

  const [ isLoaded, setIsLoaded ] = React.useState( false );
  React.useEffect( () => {
    setIsLoaded( true )
  }, [] );

  /* ------ = Output = --------------------------------------------------------------------- */

  const className = classnames( 'Img', customClassName );

  return(

    <SimpleImg
    className="Img"
    src={ imageSrc }
    placeholder={ isLoaded ? imageSrc : placeholder }
    { ...p }
    />

  );

} );

export default Image;
